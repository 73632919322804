import React, {Component} from 'react';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';
import Loader from "../assets/images/svg/loader.svg";
import { MDBDataTable, MDBBtn } from 'mdbreact';

class OurChallenges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      challenges: [],
      contactType: 'msg-success',
      contactMsg: 'Merci, nous vous recontacterons dans les plus bref délais.',
    };
    this.sendMessage = this.sendMessage.bind(this);
  }
  
  componentDidMount() {
    fetch("https://api.goaltiming.be/challenges?type=partial")
      .then(res => res.json())
      .then(
        (result) => {
          const sortedChallenges = result.sort((a, b) => a.date - b.date);
          const selectedChallenges = [];
          const arrayMonth = ['Janv.', 'Févr.', 'Mars', 'Avr.', 'Mai', 'Juin', 'Juill.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'];

          for (var i = 0; i<result.length; i++) {
            var date = new Date(sortedChallenges[i].date*1000);
            var day = date.getDate();
            var month = date.getMonth()+1;
            var year = date.getFullYear();
            var buildedDate = day + ' ' + arrayMonth[month-1] + ' ' + year;
      
            if(sortedChallenges[i].startDate != undefined && sortedChallenges[i].startDate != -3600){
              var startDate = new Date(sortedChallenges[i].startDate*1000);
              var startDay = startDate.getDate();
              var startMonth = startDate.getMonth()+1;
              var startYear = startDate.getFullYear();

              buildedDate = 'du ' + startDay + ' ' + arrayMonth[startMonth-1] + ' ' + startYear + ' au ' +  day + ' ' + arrayMonth[month-1] + ' ' + year;
            }
      
            selectedChallenges.push({
              name : sortedChallenges[i].name,
              date : buildedDate,
              url: <MDBBtn outline tag="a" href={'/challenges/' + sortedChallenges[i].ChallengeId}>Voir le détail</MDBBtn>
            });
          }

          const data = {
            columns: [
              {
                label: 'Nom',
                field: 'name',
                sort: 'asc',
              },
              {
                label: 'Date',
                field: 'date',
                sort: 'asc',
              },
              {
                label: 'Détails',
                field: 'url',
                sort: 'asc',
              }
            ],
            rows: selectedChallenges
          };

          this.setState({
            isLoaded: true,
            challenges: data
          });
        },(error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  sendMessage(){
    this.setState({
      contactType: 'msg-success show',
    });
  }

  render() {
    import('../assets/css/home.css');
    import('../assets/css/runs.css');
    const { error, isLoaded, challenges } = this.state;

    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader"><img src={Loader} alt="Loader" /></div>;
    } else{
      return (

        <div className="page-ourruns">

            <Menu />

            <section className="main-content">
                <div className="container">
                  
                  <h2 className="title">Nos challenges</h2>

                  <MDBDataTable striped bordered hover data={challenges} />
                  
                </div>
            </section>

            <section className="section-infos">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-4">
                            <div className="i-box">
                              <iframe src="/facebook.html" frameBorder="0" height="320"></iframe>
                            </div>
                        </div> */}
                        <div className="col-md-3">
                            <div className="i-box">
                                <h3 className="i-box-title">Liens Utiles</h3>

                                <ul>
                                    <li><a href="/nos-courses">Nos courses</a></li>
                                    <li><a href="/">Résultats</a></li>
                                    <li><a href="/nos-challenges">Nos Challenges</a></li>
                                    <li><a href="/#contact">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="i-box">
                                <h3 className="i-box-title">Contact</h3>

                                <ContactForm type={this.state.contactType} msg={this.state.contactMsg} sendmessage={this.sendMessage} challengeid="global" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            
          </div>
      );
    }
  }
}

export default OurChallenges