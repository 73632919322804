import React, {Component} from 'react';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import Participants from '../components/Participants';
import Live from '../components/Live';
import Form from '../components/Form';
import ContactForm from '../components/ContactForm';
import Messages from '../components/Messages';
import Loader from "../assets/images/svg/loader.svg";
import Trash from "../assets/images/svg/bin.svg";
import cookie from 'react-cookies';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

class Runs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      item: [],
      success: '',
      registrationOpened: false,
      external_register: false,
      show_preinscription: false,
      raceprice: 0,
      raceFees: 0,
      totalFees: 0,
      goals: [],
      totalPrice: 0,
      totalPriceFix: 0,
      totalPriceGoals: 0,
      type: '',
      message: '',
      contactType: 'msg-success',
      contactMsg: 'Merci, nous vous recontacterons dans les plus bref délais.',
      results: [],
      resultEmpty: '',
      discount: 0,
      typePromo: 'msg-success',
      messagePromo: 'Le code promo est appliqué.',
      raceId: '',
      selectedRace: 0,
      showFees: 'disablefees',
      cart: [],
      cartTotal: 0,
      waiting: '',
      identifier: '',
      defaultTab: 'inscription',
      onLive: false,
      mainWidth: 'col-lg-8',
      trad: false,
      counter: {
        show: 'none',
        value: 0
      },
      counterStarted: false
    };
    this.selectRace = this.selectRace.bind(this);
    this.reSelectRace = this.reSelectRace.bind(this);
    this.addGoal = this.addGoal.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.changeDiscount = this.changeDiscount.bind(this);
    this.changeCart = this.changeCart.bind(this);
    this.returnPromo = this.returnPromo.bind(this);
    this.waiting = this.waiting.bind(this);
    this.identifier = this.identifier.bind(this);
    this.resetIdentifier = this.resetIdentifier.bind(this);
  }
  
  // ============ LOAD EVENT INFORMATIONS
  componentDidMount() {
    var hash = window.location.hash
    if(hash == '#live'){
      this.setState({
        defaultTab: 'live',
      });
    }    

    fetch("https://api.goaltiming.be/events/" + this.props.match.params.id)
      .then(res => res.json())
      .then(
        (result) => {
          var today = Math.floor(new Date().getTime()/1000);
          var startRegistration = result.registrationStartDate;
          var endRegistration = result.registrationEndDate;

          if(startRegistration < today && endRegistration >= today){
            this.setState({registrationOpened: true});
          }

          if(result.rankings.length === undefined){
            result.rankings = [];
          }

          if(result.live && result.live != '' && result.live != 'none'){
            this.setState({
              mainWidth: 'col-lg-12',
              onLive: true
            });
          }

          this.setState({
            isLoaded: true,
            item: result,
            activateStep2 : '',
            show: '',
            raceFees: result.frais,
            external_register: result.external_register,
            show_preinscription: result.show_preinscription,
            trad: result.trad
          });


          if(this.state.item.frais !== "0"){
            this.setState({showFees: ''});
          }

          // CREATE RESULTS ARRAY
          var tempRace = [];
          for(var i=0; i<result.races.length; i++){
            if(result.races[i].results !== false){
              tempRace.push({
                name: result.races[i].name,
                link: result.races[i].results
              });
            }
          }

          this.setState({results: tempRace});

          if(tempRace.length !== 0){
            this.setState({resultEmpty: 'hide'});
          }
          
          // CHECK IF ITS A PAYMENT RETURN
          if(this.findGetParameter('inscription') === 'true' || this.findGetParameter('redirect_status') === 'succeeded'){
            this.setState({
              type: 'msg-success show',
              message: 'Félicitations, vous êtes bien inscrit(s) à la course, vous allez recevoir un email de confirmation.'
            });

            cookie.remove('register', { path: '/' });
          }else if(this.findGetParameter('inscription') === 'false'){
            var msg = 'Désolé, le paiement a été interrompu, si le problème persiste veuillez nous contacter via le formulaire.';

            this.setState({
              type: 'msg-error show',
              message: msg
            });

            cookie.remove('register', { path: '/' });
          }

          // SHOW COUNTER
          if(result.counter){
          fetch('https://api.goaltiming.be/events/'+result.EventId+'/counter', {
              method: 'GET'
          })
          .then(res => res.json())
          .then(
              (result) => {
                  this.setState({
                    counter: {
                      show: 'block',
                      value: result
                    }
                  });
                },(error) => {
                    console.log(error);
                }
            )
          }
        },(error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  // ============ reset identifier
  resetIdentifier(){
    this.setState({
      identifier: '',
    });
  }

  // ============ identifier
  identifier(txt){
    this.setState({
      identifier: txt,
    });
  }

  // ============ SHOW WAITING MESSAGE
  waiting(){
    this.setState({
      waiting: 'waiting',
    });
  }

  // ============ SHOW MESSAGE BOX
  sendMessage(){
    this.setState({
      contactType: 'msg-success show',
    });
  }

  // ============ FIND URL PARAM
  findGetParameter(parameterName) {
      var result = null,
          tmp = [];
      window.location.search
          .substr(1)
          .split("&")
          .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
          });
      return result;
  }

  // ============ CALCULATE PRICE
  calculatetotal(goals, price){
    this.setState({
      totalPrice: Number(goals) + Number(0) + Number(price) - Number(this.state.discount),
    });
  }

  // ============ APPLY DISCOUNT
  changeDiscount(discount){
    this.setState({discount: discount});

    this.calculatetotal(this.state.totalPriceGoals, this.state.totalPriceFix);
  }

  // ============ APPLY CART
  changeCart(cart){
    this.setState({cart: cart});

    var L = this.state.cart.length;
    var total = 0;
    for(var i=0;i<L;i++){
      total = total + this.state.cart[i].prices.total;
    }

    let totalWithFees = 0;
    let fees = 0;
    if(total <= 0){
      totalWithFees = total;
      fees = 0;
    }else if(Number(this.state.raceFees) === 0){
      totalWithFees = total;
      fees = 0;
    }else if(Number(total*this.state.raceFees) < 0.5){
      totalWithFees = total + 0.5;
      fees = 0.5;
    }else{
      totalWithFees = total + Math.round(Number(total*this.state.raceFees)*100)/100;
      fees = Math.round(Number(total*this.state.raceFees)*100)/100;
    }

    this.setState({
      cartTotal: totalWithFees,
      totalFees: fees
    });
  }

  // ============ ADD ITEM IN BASKET
  addGoal = function(goals){
    this.setState({goals: goals});
    this.setState({totalPriceGoals: 0});
    var x = 0;

    for(var i=0; i < goals.length; i++){
      if(goals[i] !== undefined){
        x = x + goals[i].totalprice;
      }
    }

    this.setState({totalPriceGoals: x});
    this.calculatetotal(x, this.state.totalPriceFix);
  }

  // =========== RECHECK
  reSelectRace = function(){
    this.setState({
      activateStep2: '',
      type: 'msg-error show',
      message: 'Désolé la course sélectionnée est complète ou il y a trop de personne dans la file d\'attente, veuillez réessayer plus tard.'
    });
  }

  // ============ CHOOSE RACE
  selectRace = function(e){
    var values = e.target.attributes;
    let cookies = cookie.loadAll();

    if(!cookies.register){
      fetch('https://api.goaltiming.be/races/registrations', {
        method: 'POST',
        body: JSON.stringify({
          "EventId": this.state.item.EventId,
	        "RaceId": values.datatarget.nodeValue
        })
    })
    .then(res => res.json())
    .then(
        (result) => {
              if(result.success === false){
                this.setState({
                  activateStep2: '',
                  type: 'msg-error show',
                  message: 'Désolé la course sélectionnée est complète ou il y a trop de personne dans la file d\'attente, veuillez réessayer plus tard.'
                });
              }else{
                let cookies = cookie.loadAll();
    
                if(!cookies.register){
                  let d = new Date();
                  d.setTime(d.getTime() + (60*60*1000));
                  cookie.save("register", result, {path: "/", expires: d});

                  let cookies = cookie.loadAll();
                  // CHECK IF YOU REGISTER COOKIE
                  if(cookies.register){
                    this.setState({
                      type: '',
                      message: '',
                      activateStep2: 'active',
                      raceprice: this.state.item.races[values.datanumber.nodeValue].preRegistrationPrices,
                      totalPriceFix: this.state.item.races[values.datanumber.nodeValue].preRegistrationPrices,
                      raceId: values.datatarget.nodeValue,
                      selectedRace: values.datanumber.nodeValue,
                      counterStarted: true
                    });
    
                    this.calculatetotal(this.state.totalPriceGoals, this.state.totalPriceFix);
                  }else{
                    this.setState({
                      type: 'msg-error show',
                      message: 'Désolé, il semblerait que vous bloquiez les cookies. Veuillez acepter les cookies de Goal Timing pour finaliser l\'inscription.'
                    });
                  }
                }
              }
          },(error) => {
              console.log(error);
          }
      )
    }else{
      cookie.remove('register', { path: '/' });

      this.selectRace(e);
    }
  }

  returnPromo(type, promo){
    this.setState({
      typePromo: type,
      messagePromo: promo
    });
  }

  // =========== check available date
  checkAvailable(date,time){
    var available = false;

    if(date != '' && date != undefined && time != '' && time != undefined){
      var closeDate = Math.floor(new Date(date + 'T' + time).getTime()/1000);
      var today = Math.floor(new Date().getTime()/1000);

      available = (closeDate >= today ? true : false)
    }else{
      available = true;
    }

    return available;
  }

  render() {
    import('../assets/css/runs.css');
    const { error, isLoaded, item, registrationOpened, external_register, show_preinscription, results, counterStarted } = this.state;

    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader"><img src={Loader} alt="Loader" /></div>;
    }else{
      return (

        <div className="page-runs">

        <Menu />

        <header className="runs-header">
          <img alt="{item.name} Banner" src={'https://www.goaltiming.be' + item.banner} className="banner" />
          <img alt="{item.name} Banner" src={'https://www.goaltiming.be' + item.banner_mobile} className="banner_mobile" />
          <div className="container">
            <h1 className="title">{item.name}</h1>
          </div>
        </header>

        <section className="runs-content">
          <div className="container">

            <div className="row">
              <div className={this.state.mainWidth}>
                <Tabs defaultActiveKey={this.state.defaultTab}>
                  <Tab eventKey="inscription" title="Inscription">
                    <p style={{display:this.state.counter.show, float: 'right'}} className="counter"><span>{this.state.counter.value}</span>€ collectés.</p>

                    {this.state.trad ? (
                      <h3 className="i-box-title" id="top">Inscription / Inschrijving</h3>
                    ) : (
                      <h3 className="i-box-title" id="top">Inscription</h3>
                    )}

                    <Messages type={this.state.type} message={this.state.message} />

                    <div dangerouslySetInnerHTML={{__html: item.description}} />

                    <div className="maincontent">
                          {external_register ? (
                            ''
                          ) : !registrationOpened ? (
                            <p>Les pré-inscriptions sont fermées.</p>
                          ) : (
                            <div>
                              <div className="step" id="step_1">
                                {this.state.trad ? (
                                  <h2>1. Sélectionnez une course / Kies je afstand</h2>
                                ) : (
                                  <h2>1. Sélectionnez une course</h2>
                                )}

                                <div className="countdown">
                                <CountdownCircleTimer
                                  isPlaying={this.state.counterStarted}
                                  duration={1800}
                                  colors={['#30694b', '#ffa701', '#ae0000']}
                                  colorsTime={[1800, 900, 0]}
                                  size={50}
                                  onComplete={() => {
                                    // do your stuff here
                                    cookie.remove('register', { path: '/' });
                                    alert('Désolé, le délai de votre inscription est expiré.')
                                    window.location.reload(false);
                                  }}
                                />
                                </div>

                                <div className="msg-alert show">
                                  {this.state.trad ? (
                                    <div>
                                      <p>
                                        Veuillez préparer toutes les informations nécessaires à l'inscription des coureurs. <br />
                                        Une fois la course sélectionnée vous aurez 30 minutes pour vous inscrire et effectuer le paiement.
                                      </p>
                                        <hr />
                                      <p>

                                        Hou alle informatie die nodig is om de inschrijving van een renner te voltooien bij de hand. Eens dat u een afstand gekozen heeft, heeft u 30 minuten om de inschrijving af te ronden en de betaling uit te voeren.
                                      </p>
                                    </div>
                                  ) : (
                                    <p>
                                      Veuillez préparer toutes les informations nécessaires à l'inscription des coureurs. <br />
                                      Une fois la course sélectionnée vous aurez 30 minutes pour vous inscrire et effectuer le paiement.
                                    </p>
                                  )}
                                </div>

                                <div className="row choose-run">
                                  {this.state.item.races.map((item, i) =>
                                      <div className="col-md-4 col-sm-12" key={i}>
                                        {this.checkAvailable(item.close,item.closeTime) ? (
                                          <div>
                                            <input type="radio" name="run" id={'run_' + i} />
                                            <label htmlFor={'run_' + i} datatarget={item.id} datanumber={i} onClick={this.selectRace}>{item.name}</label>
                                          </div>
                                        ):('')}
                                      </div>
                                // this.checkAvailable(item.close,item.closeTime)
                                  )}
                                </div>
                              </div>

                              <div className={"formState " + this.state.activateStep2}>
                                <Form reselectrace={this.reSelectRace} raceid={this.state.raceId} dataitem={this.state.item} datanumber={this.state.selectedRace} datagoals={this.addGoal} totalPrice={this.state.totalPrice}  raceprice={this.state.raceprice} racegoals={this.state.goals} cdiscount={this.changeDiscount} changeCart={this.changeCart} cart={this.state.cart} cartTotal={this.state.cartTotal} discount={this.state.discount} alertpromo={this.returnPromo} msgpromo={this.state.messagePromo} typepromo={this.state.typePromo} waiting={this.waiting} waitingmsg={this.state.waiting} totalfee={this.state.totalFees} identifier={this.identifier} identifiermsg={this.state.identifier} resetIdentifier={this.resetIdentifier} trad={this.state.trad} />
                              </div>
                            </div>
                          )}
                    </div>
                  </Tab>
                  {this.state.item.races.length > 0 && !show_preinscription ? (
                    <Tab eventKey="participants" title="Participants">
                      <Participants eventid={this.state.item.EventId} races={this.state.item.races} />
                    </Tab>
                  ) : ('')}
                  
                  {results.length > 0 || item.rankings.length > 0 ? (
                    <Tab eventKey="resultats" title="Résultats">
                      <div className="result-box">
                        <h3 className="i-box-title">Résultats</h3>
                        <ul className="row">
                          {results.map((item, i) =>
                            <li className="col-lg-6" key={i}>
                              <a href={'https://www.goaltiming.be' + item.link} target="_blank">{item.name}</a>
                            </li>
                          )}
                          {item.rankings.map((item, i) =>
                            <li className="col-lg-6" key={i}>
                              <a href={'https://www.goaltiming.be' + item.image} target="_blank">{item.name}</a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </Tab>
                  ) : ('')}
                  
                  {this.state.item.live != 'none' && this.state.item.live != '' ? (
                    <Tab eventKey="live" title="Classements">
                        <Live idlive={this.state.item.live} />
                    </Tab>
                  ) : ('')}
                </Tabs>
              </div>
              {!this.state.onLive ? (
                <div className="col-lg-4 commande">
                  <Sidebar item={item} races={this.state.item.races} external_register={external_register} trad={this.state.trad} />

                  <div className="cart">

                    {this.state.trad ? (
                      <h3>Mon panier / Mijn mandje</h3>
                    ) : (
                      <h3>Mon panier</h3>
                    )}
                    <div className="pricing">
                      {this.state.cart.length > 0 ? (
                        <dl>
                          {this.state.cart.map((runner, i) =>
                              <div key={i} className="cart-list">
                                <dt className="first cart-runner">{runner.name} {runner.firstname}</dt>
                                <dd className="first">{runner.raceChoosed}</dd>
                                <dt>{runner.raceName}</dt>
                                <dd>{runner.prices.race}€</dd>
                                  {runner.items.map((item, j) =>
                                    <div key={j}>
                                      <dt>{item.number}x {item.name}</dt>
                                      <dd>{item.totalprice}€</dd>
                                    </div>
                                  )}
                                {runner.prices.discount > 0 ? (
                                  <div>
                                    {this.state.trad ? (
                                      <dt>Réductions / Korting</dt>
                                    ) : (
                                      <dt>Réductions</dt>
                                    )}
                                    <dd>-{runner.prices.discount}€</dd>
                                  </div>
                                ) : ('')}
                                {this.state.trad ? (
                                  <dt>Sous-Total / Totaal</dt>
                                ) : (
                                  <dt>Sous-Total</dt>
                                )}
                                <dd>{runner.prices.total}€</dd>
                              </div>
                          )}
                          <div className="cart-total"> 
                            {this.state.trad ? (
                              <dt>Frais / Kosten</dt>
                            ) : (
                              <dt>Frais</dt>
                            )}
                            <dd>{this.state.totalFees}€</dd>
                            {this.state.trad ? (
                              <dt>Total / Algemeen&nbsp;totaal</dt>
                            ) : (
                              <dt>Total</dt>
                            )}
                            <dd><strong>{this.state.cartTotal}€</strong></dd>
                          </div>
                        </dl>
                      ) : (
                        <span>
                          {this.state.trad ? (
                            <div>
                              Veuillez remplir le formulaire d'inscription. <hr /> Gelieve het inschrijvingsformulier in te vullen
                            </div>
                          ) : (
                            <span>Veuillez remplir le formulaire d'inscription.</span>
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ) : ('')}
             
            </div>
          </div>
        </section>

        <section className="runs-infos">
          <div className="container">
            <div className="row">
              <div className="col-md-6 i-box-center">
                  <div className="i-box">
                      <h3 className="i-box-title">Une question sur l'évènement ?</h3>

                      <ContactForm type={this.state.contactType} msg={this.state.contactMsg} sendmessage={this.sendMessage} eventid={this.props.match.params.id} />
                  </div>
              </div>
            </div>
          </div>
        </section>

        <Footer dataitem={this.state.item} />
        
      </div>
      );
    }
  }
}

export default Runs