import React, {Component} from 'react';
import Menu from '../components/Menu';
import Mapsvg from "../assets/images/svg/map.svg";
import Form from "../assets/images/svg/clipboard.svg";
import Card from "../assets/images/svg/debit-card.svg";

export default class Header extends Component {
    render(){
        import('../assets/css/header.css');
        return (
            <header className="main-header">

                <Menu />
                
                <section className="section-intro">
                    <div className="container">
                        <h2 className="title">Chronométreur d'événements sportifs</h2>

                        <div className="easy-container">
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <div className="easy-box">
                                        <div className="round"><img alt="Carte" src={Mapsvg} /></div>
                                        <h3 className="easy-box-title">Choisissez<br />votre course</h3>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="easy-box">
                                        <div className="round"><img alt="Formulaire" src={Form} /></div>
                                        <h3 className="easy-box-title">Remplissez<br />le formulaire d'inscription</h3>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="easy-box">
                                        <div className="round"><img alt="Paiement" src={Card} /></div>
                                        <h3 className="easy-box-title">Payez en toute sécurité<br />et validez votre inscription</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


               {/* <form className="container">
                    <div className="search-form">
                        <input type="text" placeholder="Rechercher une course" />
                        <button>Rechercher</button>
                    </div>
                </form>
                */}
            </header>
        )
    }
}