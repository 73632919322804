import React from 'react';
import useForm from 'react-hook-form';
import Messages from '../components/Messages';

export default function ContacForm(props) {
    const { register, handleSubmit, errors, reset } = useForm();

    const onSubmit = function(d){
        fetch('https://api.goaltiming.be/races/contact/' + props.eventid, {
            method: 'POST',
            body: JSON.stringify({
                "message": "<strong>Nom:</strong> "+ d.name +" <br/><strong>Email:</strong> "+ d.email +" <br/> <strong>Message:</strong><br/>" + d.message
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(result.success){
                    props.sendmessage();

                    reset({
                        name: '',
                        email: '',
                        text: ''
                    });
                }else{

                }
            },(error) => {
                console.log(error);
            }
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Messages type={props.type} message={props.msg} />

            <input type="text" placeholder="Nom" name="name" ref={register({required: true})} />
            <small className="form-text form-error">{errors.name && <span>Ce champ est requis</span>}</small>

            <input type="text" placeholder="Email" name="email"ref={register({required: true, pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i }})} />
            <small className="form-text form-error">{errors.email && <span>Un email est requis</span>}</small>

            <textarea  placeholder="Votre message..." name="message" ref={register({required: true})}></textarea>
            <small className="form-text form-error">{errors.message && <span>Ce champ est requis</span>}</small>

            <button type="submit" className="button">Envoyer</button>
        </form>
    );
}