import React, {Component} from 'react';
import Iframe from 'react-iframe'

class Live extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      datas: []
    };
  }

  render() {
    return (
        <Iframe url={"/live.html?id=" + this.props.idlive}
                width="100%"
                height="1000px"
                frameBorder="0"
        >
        </Iframe>
    );
  }
}

export default Live