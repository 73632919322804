import React, {Component} from 'react';
import Fb from "../assets/images/svg/fb.svg";
import Tw from "../assets/images/svg/tw.svg";

export default class Footer extends Component {
    render(){
        import('../assets/css/footer.css');
        return (
            <footer className="main-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-8"><p>© <a href="https://www.beezi.be/" target="_blank">Beezi</a> & <a href="https://www.bee-factory.be/" target="_blank">Bee Factory</a> 2019</p></div>
                        <div className="col-4">
                            <ul>
                                <li><a href="https://www.facebook.com/GoalTIming/" className="pict-fb" target="_blank"><img alt="" src={Fb} /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}