import React from 'react';
import { MDBDataTable, MDBBtn } from 'mdbreact';

export default function ChallengeEventsList(props) {
    const sections = props.sections
    const selectedEvents = []
    const arrayMonth = ['Janv.', 'Févr.', 'Mars', 'Avr.', 'Mai', 'Juin', 'Juill.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'];

    for(let i=0; i<sections.length; i++) {    
        for(let j=0; j<sections[i].events.length; j++){
            var date = new Date(sections[i].events[j].date*1000);
            var day = date.getDate();
            var month = date.getMonth()+1;
            var year = date.getFullYear();
            var buildedDate = day + ' ' + arrayMonth[month-1] + ' ' + year;
            var exist = false;

            for(let k=0; k<selectedEvents.length; k++){
                if(sections[i].events[j].name === selectedEvents[k].evenement){
                    exist = true;
                }
            }

            if(exist !== true){
                selectedEvents.push({
                    section: sections[i].name,
                    evenement: sections[i].events[j].name,
                    url: <MDBBtn outline tag="a" target="_blank" href={'/courses/' + sections[i].events[j].eventid}>Détail</MDBBtn>,
                    date: buildedDate,
                    timestamp: sections[i].events[j].date
                });
            }
        }
    }

    const sortedEvents = selectedEvents.sort((a, b) => a.timestamp - b.timestamp);

    const data = {  
        columns: [
            {
                label: 'Evènement',
                field: 'evenement',
                sort: 'asc'
            },
            {
                label: 'Date',
                field: 'date',
            },
            {
                label: 'Détails',
                field: 'url',
            }
        ],
        rows: sortedEvents
    };

    return (
        <div>
             <MDBDataTable striped bordered hover data={data} />
        </div>
    );
}