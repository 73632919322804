import React, {Component} from 'react';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';
import Loader from "../assets/images/svg/loader.svg";
import { MDBDataTable, MDBBtn } from 'mdbreact';

class OurResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      events: [],
      contactType: 'msg-success',
      contactMsg: 'Merci, nous vous recontacterons dans les plus bref délais.',
    };
    this.sendMessage = this.sendMessage.bind(this);
  }
  
  componentDidMount() {
    fetch("https://api.goaltiming.be/events?type=all")
      .then(res => res.json())
      .then(
        (result) => {
        const events = result;
        const sortedEvents = events.sort((a, b) => b.date - a.date);
        const selectedEvents = [];
        const arrayMonth = ['Janv.', 'Févr.', 'Mars', 'Avr.', 'Mai', 'Juin', 'Juill.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'];
    
          

        for (var i = 0; i<sortedEvents.length; i++) {
            var haveResults = false; 
            for(var j = 0; j<sortedEvents[i].races.length; j++){
              if(sortedEvents[i].races[j].results){
                  haveResults = true;
              }else if(sortedEvents[i].live && sortedEvents[i].live != '' &&  sortedEvents[i].live != 'none'){
                
                haveResults = true;
              }                    
            }

            if(haveResults){
                var today = new Date();
                var date = new Date(sortedEvents[i].date*1000);
                var day = date.getDate();
                var month = date.getMonth()+1;
                var year = date.getFullYear();

                if(sortedEvents[i].live && sortedEvents[i].live != 'none' && sortedEvents[i].live != '' && today < date){
                  selectedEvents.unshift({
                    name : sortedEvents[i].name,
                    date : day + ' ' + arrayMonth[month-1] + ' ' + year,
                    url: <MDBBtn outline tag="a" class="onlive" href={'/courses/' + sortedEvents[i].EventId + '#live'}>Résultats en direct</MDBBtn>
                  });
                }else{
                  selectedEvents.push({
                    name : sortedEvents[i].name,
                    date : day + ' ' + arrayMonth[month-1] + ' ' + year,
                    url: <MDBBtn outline tag="a" href={'/courses/' + sortedEvents[i].EventId}>Voir les résultats</MDBBtn>
                  });
                }
          
                
            }
        }

          const data = {
            columns: [
              {
                label: 'Nom',
                field: 'name',
                sort: 'asc',
              },
              {
                label: 'Date',
                field: 'date',
                sort: 'asc',
              },
              {
                label: '',
                field: 'url',
                sort: 'asc',
              }
            ],
            rows: selectedEvents
          };

          this.setState({
            isLoaded: true,
            events: data
          });
        },(error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  sendMessage(){
    this.setState({
      contactType: 'msg-success show',
    });
  }

  render() {
    import('../assets/css/home.css');
    import('../assets/css/runs.css');
    const { error, isLoaded, events } = this.state;

    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader"><img src={Loader} alt="Loader" /></div>;
    } else{
      return (

        <div className="page-ourruns">

            <Menu />

            <section className="main-content">
                <div className="container">
                  
                  <h2 className="title">Les résultats</h2>

                  <MDBDataTable striped bordered hover data={events} />
                  
                </div>
            </section>

            <section className="section-infos">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="i-box">
                                <h3 className="i-box-title">Liens Utiles</h3>

                                <ul>
                                    <li><a href="/nos-courses">Nos courses</a></li>
                                    <li><a href="/resultats">Résultats</a></li>
                                    <li><a href="/#contact">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="i-box">
                                <h3 className="i-box-title">Contact</h3>

                                <ContactForm type={this.state.contactType} msg={this.state.contactMsg} sendmessage={this.sendMessage} eventid="global" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            
          </div>
      );
    }
  }
}

export default OurResults