import React, {Component} from 'react';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';
import Loader from "../assets/images/svg/loader.svg";
import { MDBDataTable, MDBBtn } from 'mdbreact';

class Runners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      events: [],
      contactType: 'msg-success',
      contactMsg: 'Merci, nous vous recontacterons dans les plus bref délais.',
      rname: '',
      ename: ''
    };
    this.sendMessage = this.sendMessage.bind(this);
  }

  // ============ FIND URL PARAM
  findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
  }
  
  componentDidMount() {
    this.setState({
        rname: this.findGetParameter('rname'),
        ename: this.findGetParameter('ename')
    });
    fetch("https://api.goaltiming.be/participants/events/"+this.props.match.params.eid+"/races/" + this.props.match.params.rid + "/registered")
      .then(res => res.json())
      .then(
        (result) => {
          result.sort(function(a, b) {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
          const data = {
            columns: [
              {
                label: 'Nom',
                field: 'name',
                sort: 'asc',
              },
              {
                label: 'Prénom',
                field: 'firstname',
                sort: 'asc',
              }
            ],
            rows: result
          };

          this.setState({
            isLoaded: true,
            events: data
          });
        },(error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  sendMessage(){
    this.setState({
      contactType: 'msg-success show',
    });
  }

  render() {
    import('../assets/css/home.css');
    import('../assets/css/runs.css');
    const { error, isLoaded, events, ename, rname } = this.state;

    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader"><img src={Loader} alt="Loader" /></div>;
    } else{
      return (

        <div className="page-ourruns">

            <Menu />

            <section className="main-content">
                <div className="container">
                  
                  <h2 className="title">{ename} : {rname} (Participants)</h2>

                  <MDBDataTable striped bordered hover data={events} />
                  
                </div>
            </section>

            <section className="section-infos">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="i-box">
                              <iframe src="/facebook.html" frameBorder="0" height="320"></iframe>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="i-box">
                                <h3 className="i-box-title">Liens Utiles</h3>

                                <ul>
                                    <li><a href="/nos-courses">Nos courses</a></li>
                                    <li><a href="/resultats">Résultats</a></li>
                                    <li><a href="/#contact">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="i-box">
                                <h3 className="i-box-title">Contact</h3>

                                <ContactForm type={this.state.contactType} msg={this.state.contactMsg} sendmessage={this.sendMessage} eventid="global" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            
          </div>
      );
    }
  }
}

export default Runners