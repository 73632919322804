import React, {Component} from 'react';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import SidebarChallenge from '../components/SidebarChallenge';
import ChallengeEventsList from '../components/ChallengeEventsList';
import Challengers from '../components/Challengers';
import Live from '../components/Live';
import FormChallenger from '../components/FormChallenger';
import ContactForm from '../components/ContactForm';
import Messages from '../components/Messages';
import Loader from "../assets/images/svg/loader.svg";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

class Challenges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      item: [],
      success: '',
      registrationOpened: false,
      external_register: false,
      show_preinscription: false,
      sectionprice: 0,
      sectionFees: 0,
      totalFees: 0,
      goals: [],
      totalPrice: 0,
      totalPriceFix: 0,
      totalPriceGoals: 0,
      type: '',
      message: '',
      contactType: 'msg-success',
      contactMsg: 'Merci, nous vous recontacterons dans les plus bref délais.',
      results: [],
      resultEmpty: '',
      discount: 0,
      typePromo: 'msg-success',
      messagePromo: 'Le code promo est appliqué.',
      sectionId: '',
      selectedSection: 0,
      showFees: 'disablefees',
      cart: [],
      cartTotal: 0,
      waiting: '',
      defaultTab: 'inscription',
      onLive: false,
      mainWidth: 'col-lg-8',
      counter: {
        show: 'none',
        value: 0
      },
      counterStarted: false
    };
    this.selectSection = this.selectSection.bind(this);
    this.reSelectSection = this.reSelectSection.bind(this);
    this.addGoal = this.addGoal.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.changeDiscount = this.changeDiscount.bind(this);
    this.changeCart = this.changeCart.bind(this);
    this.returnPromo = this.returnPromo.bind(this);
    this.waiting = this.waiting.bind(this);
  }
  
  // ============ LOAD EVENT INFORMATIONS
  componentDidMount() {
    var hash = window.location.hash
    if(hash == '#live'){
      this.setState({
        defaultTab: 'live',
      });
    }    

    fetch("https://api.goaltiming.be/challenges/" + this.props.match.params.id)
      .then(res => res.json())
      .then(
        (result) => {

          var today = Math.floor(new Date().getTime()/1000);
          var startRegistration = result.registrationStartDate;
          var endRegistration = result.registrationEndDate;

          if(startRegistration < today && endRegistration >= today){
            this.setState({registrationOpened: true});
          }

          if(result.rankings.length === undefined){
            result.rankings = [];
          }

          if(result.sections.length === undefined){
            result.sections = [];
          }else{
            for(let i=0;i<result.sections.length;i++){
              if(result.sections[i].events === undefined){
                result.sections[i].events = []
              }
            }
          }

          if(result.live && result.live != '' && result.live != 'none'){
            this.setState({
              mainWidth: 'col-lg-12',
              onLive: true
            });
          }

          this.setState({
            isLoaded: true,
            item: result,
            activateStep2 : '',
            show: '',
            sectionFees: result.frais,
            external_register: result.external_register,
            show_preinscription: result.show_preinscription
          });

          if(this.state.item.frais !== "0"){
            this.setState({showFees: ''});
          }

          // CREATE RESULTS ARRAY
          var tempSection = [];
          for(var i=0; i<result.sections.length; i++){
            if(result.sections[i].results !== false){
              tempSection.push({
                name: result.sections[i].name,
                link: result.sections[i].results
              });
            }
          }

          this.setState({results: tempSection});

          if(tempSection.length !== 0){
            this.setState({resultEmpty: 'hide'});
          }
          
          // CHECK IF ITS A PAYMENT RETURN
          if(this.findGetParameter('inscription') === 'true' || this.findGetParameter('redirect_status') === 'succeeded'){
            this.setState({
              type: 'msg-success show',
              message: 'Félicitations, vous êtes bien inscrit(s) à la section, vous allez recevoir un email de confirmation.'
            });

          }else if(this.findGetParameter('inscription') === 'false'){
            var msg = 'Désolé, le paiement a été interrompu, si le problème persiste veuillez nous contacter via le formulaire.';

            this.setState({
              type: 'msg-error show',
              message: msg
            });

          }

          // SHOW COUNTER
          if(result.counter){
          fetch('https://api.goaltiming.be/challenges/'+result.ChallengeId+'/counter', {
              method: 'GET'
          })
          .then(res => res.json())
          .then(
              (result) => {
                  this.setState({
                    counter: {
                      show: 'block',
                      value: result
                    }
                  });
                },(error) => {
                    console.log(error);
                }
            )
          }
        },(error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  // ============ SHOW WAITING MESSAGE
  waiting(){
    this.setState({
      waiting: 'waiting',
    });
  }

  // ============ SHOW MESSAGE BOX
  sendMessage(){
    this.setState({
      contactType: 'msg-success show',
    });
  }

  // ============ FIND URL PARAM
  findGetParameter(parameterName) {
      var result = null,
          tmp = [];
      window.location.search
          .substr(1)
          .split("&")
          .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
          });
      return result;
  }

  // ============ CALCULATE PRICE
  calculatetotal(goals, price){
    console.log(goals + ' / ' + price)

    this.setState({
      totalPrice: Number(goals) + Number(0) + Number(price) - Number(this.state.discount),
    });
  }

  // ============ APPLY DISCOUNT
  changeDiscount(discount){
    this.setState({discount: discount});

    this.calculatetotal(this.state.totalPriceGoals, this.state.totalPriceFix);
  }

  // ============ APPLY CART
  changeCart(cart){
    this.setState({cart: cart});

    var L = this.state.cart.length;
    var total = 0;
    for(var i=0;i<L;i++){
      total = total + this.state.cart[i].prices.total;
    }

    let totalWithFees = 0;
    let fees = 0;
    if(total <= 0){
      totalWithFees = total;
      fees = 0;
    }else if(Number(this.state.sectionFees) === 0){
      totalWithFees = total;
      fees = 0;
    }else if(Number(total*this.state.sectionFees) < 0.5){
      totalWithFees = total + 0.5;
      fees = 0.5;
    }else{
      totalWithFees = total + Math.round(Number(total*this.state.sectionFees)*100)/100;
      fees = Math.round(Number(total*this.state.sectionFees)*100)/100;
    }

    this.setState({
      cartTotal: totalWithFees,
      totalFees: fees
    });
  }

  // ============ ADD ITEM IN BASKET
  addGoal = function(goals){
    this.setState({goals: goals});
    this.setState({totalPriceGoals: 0});
    var x = 0;

    for(var i=0; i < goals.length; i++){
      if(goals[i] !== undefined){
        x = x + goals[i].totalprice;
      }
    }

    this.setState({totalPriceGoals: x});
    this.calculatetotal(x, this.state.totalPriceFix);
  }

  // =========== RECHECK
  reSelectSection = function(){
    this.setState({
      activateStep2: '',
      type: 'msg-error show',
      message: 'Désolé la section sélectionnée est complète ou il y a trop de personne dans la file d\'attente, veuillez réessayer plus tard.'
    });
  }

  // ============ CHOOSE RACE
  selectSection = function(e){
    var values = e.target.attributes;

    this.setState({
      type: '',
      message: '',
      activateStep2: 'active',
      sectionprice: this.state.item.sections[values.datanumber.nodeValue].preRegistrationPrices,
      totalPriceFix: this.state.item.sections[values.datanumber.nodeValue].preRegistrationPrices,
      sectionId: values.datatarget.nodeValue,
      selectedSection: values.datanumber.nodeValue,
      counterStarted: true
    });

    setTimeout(() => {
      this.calculatetotal(this.state.totalPriceGoals, this.state.totalPriceFix);
    }, '500')
  }
  

  returnPromo(type, promo){
    this.setState({
      typePromo: type,
      messagePromo: promo
    });
  }

  // =========== check available date
  checkAvailable(date,time){
    var available = false;

    if(date != '' && date != undefined && time != '' && time != undefined){
      var closeDate = Math.floor(new Date(date + 'T' + time).getTime()/1000);
      var today = Math.floor(new Date().getTime()/1000);

      available = (closeDate >= today ? true : false)
    }else{
      available = true;
    }

    return available;
  }

  render() {
    import('../assets/css/runs.css');
    const { error, isLoaded, item, registrationOpened, external_register, show_preinscription, results, counterStarted } = this.state;

    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader"><img src={Loader} alt="Loader" /></div>;
    }else{
      return (

        <div className="page-runs">

        <Menu />

        <header className="runs-header">
          <img alt="{item.name} Banner" src={'https://www.goaltiming.be' + item.banner} className="banner" />
          <img alt="{item.name} Banner" src={'https://www.goaltiming.be' + item.banner_mobile} className="banner_mobile" />
          <div className="container">
            <h1 className="title">{item.name}</h1>
          </div>
        </header>

        <section className="runs-content">
          <div className="container">

            <div className="row">
              <div className={this.state.mainWidth}>
                <Tabs defaultActiveKey={this.state.defaultTab}>
                  <Tab eventKey="inscription" title="Inscription">
                    <p style={{display:this.state.counter.show, float: 'right'}} className="counter"><span>{this.state.counter.value}</span>€ collectés.</p>
                    
                    <a href={'https://www.goaltiming.be' + this.state.item.addRules} target="_blank" className="button submitbtn orange addother" style={{float: 'right'}}>Règlement</a>
                    <a href="#top" className="button submitbtn orange addother" style={{float: 'left'}}>Inscription au challenge</a>

                    <div style={{clear: 'both'}}></div>

                    <Messages type={this.state.type} message={this.state.message} />

                    <div dangerouslySetInnerHTML={{__html: item.description}} />

                    <br />

                    <ChallengeEventsList sections={this.state.item.sections} />

                    <div className="maincontent">
                          {external_register ? (
                            ''
                          ) : !registrationOpened ? (
                            <p>Les pré-inscriptions sont fermées.</p>
                          ) : (
                            <div>

                              <h3 className="i-box-title" id="top">Inscription</h3>

                              <div className="step" id="step_1">
                                <h2>1. Sélectionnez une section</h2>

                                <div className="countdown">
                                <CountdownCircleTimer
                                  isPlaying={this.state.counterStarted}
                                  duration={1800}
                                  colors={['#30694b', '#ffa701', '#ae0000']}
                                  colorsTime={[1800, 900, 0]}
                                  size={50}
                                  onComplete={() => {
                                    // do your stuff here
                                    alert('Désolé, le délai de votre inscription est expiré.')
                                    window.location.reload(false);
                                  }}
                                />
                                </div>

                                <div className="msg-alert show">
                                    <p>
                                      Veuillez préparer toutes les informations nécessaires à l'inscription des coureurs. <br />
                                      Une fois la section sélectionnée vous aurez 30 minutes pour vous inscrire et effectuer le paiement.
                                    </p>
                                </div>

                                <div className="row choose-run">
                                  {this.state.item.sections.map((item, i) =>
                                      <div className="col-md-4 col-sm-12" key={i}>
                                        {this.checkAvailable(item.close,item.closeTime) ? (
                                          <div>
                                            <input type="radio" name="run" id={'run_' + i} />
                                            <label htmlFor={'run_' + i} datatarget={item.id} datanumber={i} onClick={this.selectSection}>{item.name}</label>
                                          </div>
                                        ):('')}
                                      </div>
                                // this.checkAvailable(item.close,item.closeTime)
                                  )}
                                </div>
                              </div>

                              <div className={"formState " + this.state.activateStep2}>
                                <FormChallenger reselectsection={this.reSelectSection} sectionid={this.state.sectionId} dataitem={this.state.item} datanumber={this.state.selectedSection} datagoals={this.addGoal} totalPrice={this.state.totalPrice}  sectionprice={this.state.sectionprice} sectiongoals={this.state.goals} cdiscount={this.changeDiscount} changeCart={this.changeCart} cart={this.state.cart} cartTotal={this.state.cartTotal} discount={this.state.discount} alertpromo={this.returnPromo} msgpromo={this.state.messagePromo} typepromo={this.state.typePromo} waiting={this.waiting} waitingmsg={this.state.waiting} totalfee={this.state.totalFees} />
                              </div>
                            </div>
                          )}
                    </div>
                  </Tab>
                  {this.state.item.sections.length > 0 && !show_preinscription ? (
                    <Tab eventKey="participants" title="Participants">
                      <Challengers challengeid={this.state.item.ChallengeId} sections={this.state.item.sections} />
                    </Tab>
                  ) : ('')}
                  
                  {results.length > 0 || item.rankings.length > 0 ? (
                    <Tab eventKey="resultats" title="Résultats">
                      <div className="result-box">
                        <h3 className="i-box-title">Résultats</h3>
                        <ul className="row">
                          {results.map((item, i) =>
                            <li className="col-lg-6" key={i}>
                              <a href={'https://www.goaltiming.be' + item.link} target="_blank">{item.name}</a>
                            </li>
                          )}
                          {item.rankings.map((item, i) =>
                            <li className="col-lg-6" key={i}>
                              <a href={'https://www.goaltiming.be' + item.image} target="_blank">{item.name}</a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </Tab>
                  ) : ('')}
                  
                  {this.state.item.live != 'none' && this.state.item.live != '' ? (
                    <Tab eventKey="live" title="Classements">
                        <Live idlive={this.state.item.live} />
                    </Tab>
                  ) : ('')}
                </Tabs>
              </div>
              {!this.state.onLive ? (
                <div className="col-lg-4 commande">
                  <SidebarChallenge item={item} sections={this.state.item.sections} external_register={external_register} />

                  <div className="cart">
                    <h3>Mon panier</h3>
                    <div className="pricing">
                      {this.state.cart.length > 0 ? (
                        <dl>
                          {this.state.cart.map((runner, i) =>
                              <div key={i} className="cart-list">
                                <dt className="first cart-runner">{runner.name} {runner.firstname}</dt>
                                <dd className="first">{runner.sectionChoosed}</dd>
                                <dt>{runner.sectionName}</dt>
                                <dd>{runner.prices.section}€</dd>
                                  {runner.items.map((item, j) =>
                                    <div key={j}>
                                      <dt>{item.number}x {item.name}</dt>
                                      <dd>{item.totalprice}€</dd>
                                    </div>
                                  )}
                                {runner.prices.discount > 0 ? (
                                  <div>
                                    <dt>Réductions</dt>
                                    <dd>-{runner.prices.discount}€</dd>
                                  </div>
                                ) : ('')}
                                <dt>Sous-total</dt>
                                <dd>{runner.prices.total}€</dd>
                              </div>
                          )}
                          <div className="cart-total"> 
                            <dt>Frais</dt>
                            <dd>{this.state.totalFees}€</dd>
                            <dt>Total</dt>
                            <dd><strong>{this.state.cartTotal}€</strong></dd>
                          </div>
                        </dl>
                      ) : ('Veuillez remplir le formulaire d\'inscription.')}
                    </div>
                  </div>
                </div>
              ) : ('')}
             
            </div>
          </div>
        </section>

        <section className="runs-infos">
          <div className="container">
            <div className="row">
              <div className="col-md-6 i-box-center">
                  <div className="i-box">
                      <h3 className="i-box-title">Une question sur l'évènement ?</h3>

                      <ContactForm type={this.state.contactType} msg={this.state.contactMsg} sendmessage={this.sendMessage} challengeid={this.props.match.params.id} />
                  </div>
              </div>
            </div>
          </div>
        </section>

        <Footer dataitem={this.state.item} />
        
      </div>
      );
    }
  }
}

export default Challenges