import React from 'react';
import useForm from 'react-hook-form';
import Messages from '../components/Messages';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import ReactTooltip from 'react-tooltip';
import CardCredit from "../assets/images/card-credit.png";
import CardBancontact from "../assets/images/card-bancontact.png";

let backupItem = [];
let validatePromo = 'nocode';
let promoValue = 0;
let entrants = [];
let metadatas = [];

export default function Form(props) {
    const { register: register, errors: errors, handleSubmit: handleSubmit, reset} = useForm();
    const { register: payment, errors: paymenterrors, handleSubmit: handlePayment} = useForm();
    
    const item = props.dataitem;
    const stripe = window.Stripe('pk_live_gFPHZPFRhngDjMDOa7gIamJa00qPLoSAH6');
    // const stripe = window.Stripe('pk_test_8bCRmwUoaOMne3AZSrd3rxLE00mLKJhlq1');
    let promo = '';
    let customFields = [];

    if(item.sections[props.datanumber].customFields === undefined){
        item.sections[props.datanumber].customFields = [];
    }else{
        let L = item.sections[props.datanumber].customFields.length;
        for(var i=0;i<L;i++){
            if(item.sections[props.datanumber].customFields[i].values != undefined){
                let customFieldsValues = item.sections[props.datanumber].customFields[i].values;
                let customFieldsDatas = customFieldsValues.split('\n');
                customFields.push(customFieldsDatas);
            }else{
                customFields.push(item.sections[props.datanumber].customFields[i].values);
            }
        }
    }

    // ============ SUBMIT FORM
    const addAnother = function(d){
        var customFields = []
        for(var i = 0; i<item.sections[props.datanumber].customFields.length; i++){
            if(item.sections[props.datanumber].customFields[i].check === true){
                customFields.push({
                    name: item.sections[props.datanumber].customFields[i].label,
                    value: item.sections[props.datanumber].customFields[i].value,
                    id: item.sections[props.datanumber].customFields[i].id
                });
            }
        }

        let date = new Date();
        let timestamp = date.getTime();

        var result = d;
            result.payement = {
                mode: 'waiting',
                date: timestamp/1000,
                promoCode: validatePromo
            };
            result.address = d.address + ', ' + d.cp + ' ' + d.city;
            result.customFields = customFields;
            result.items = backupItem;
            result.prices = {
                section: Number(props.sectionprice),
                discount: props.discount,
                total: props.totalPrice
            };
            result.sectionName = item.name;
            result.challengeid = item.ChallengeId;
            result.sectionid = props.sectionid;
            result.sectionChoosed = document.querySelector('.page-runs .runs-content .choose-run input[type="radio"]:checked + label').innerHTML;

        entrants.push(result);        
        props.changeCart(entrants);
        backupItem = [];
        props.datagoals(backupItem);
        props.cdiscount(0);
        promoValue = 0;
        props.alertpromo('msg-success', 'Le code a bien été validé. Vous avez ' + result.discount + '€ de réduction sur la course.');
        reset(entrants);

    }
    const onSubmit = function(d){
        var i = 0;
        var L = entrants.length;
        props.waiting();

        inscriptions(entrants[i], i, L);
    }

    const inscriptions = function(data, i, L){
        if(i < L){
            fetch('https://api.goaltiming.be/challengers/new',{ method: 'GET' })
            .then(res => res.json())
            .then(
                (result) => {
                    result.name = data.name;
                    result.firstname = data.firstname;
                    result.email = data.email;
                    result.sex = data.sex;
                    result.dob = data.dob;
                    result.address = data.address;
                    result.country = data.country;
                    result.club = data.club;
                    result.customFields = data.customFields;
                    result.payement = data.payement;
                    result.items = data.items;
                    result.accept_rgpd = data.rgpd;
                    result.accept_newsletter = data.newsletter;
                    result.is_in_good_health = data.is_in_good_health;
                    result.accept_rules = data.accept_rules;
                    result.payement_status = 'Waiting';
                    result.payement.totalprice = props.cartTotal;
                    result.payement.totalfrais = props.totalfee;
                    if(props.cartTotal <= 0){
                        result.payement.mode = 'free';
                    }
    
                    metadatas.push(data.sectionid + '_' + data.challengeid + '_' + result.ChallengerId + '_challenge');

                    fetch('https://api.goaltiming.be/challengers/challenges/'+ data.challengeid +'/sections/' + data.sectionid,{ 
                        method: 'POST' ,
                        body: JSON.stringify(result)
                    })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            i++;
                            inscriptions(entrants[i], i, L);
                        },(error) => {
                            console.log(error);
                        }
                    );
                },(error) => {
                    console.log(error);
                }
            );
        }else{
            if(props.cartTotal > 0){
                fetch('https://api.goaltiming.be/events/session', {
                    method: 'POST',
                    body: JSON.stringify({
                        name: entrants[0].name + ' ' + entrants[0].firstname,
                        description: item.name,
                        data_description: item.name,
                        amount: Math.round(props.cartTotal*100),
                        currency: 'eur',
                        customer_email: entrants[0].email,
                        quantity: 1,
                        metadata: metadatas,
                        success_url: 'https://www.goaltiming.be/challenges/' + item.ChallengeId + '?session_id={CHECKOUT_SESSION_ID}&inscription=true',
                        cancel_url: 'https://www.goaltiming.be/challenges/' + item.ChallengeId + '?inscription=false'
                    })
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        if(result.id){
                            stripe.redirectToCheckout({
                                sessionId: result.id
                            }).then(function (result) {
                                // console.log(result);
                            });
                        }
                    },(error) => {
                        console.log(error);
                    }
                )
                // window.location.replace('/challenges/' + item.ChallengeId + '?inscription=true');
            }else{
                window.location.replace('/challenges/' + item.ChallengeId + '?inscription=true');
            }
        }
    }
    
    const handleChange = function(e){
        e.preventDefault();
        if(e.target.value < 0){
            e.target.value = 0;
        }

        backupItem[e.target.attributes.datakey.nodeValue] = {
            number: Math.round(e.target.value),
            name: e.target.attributes.dataname.nodeValue,
            price: e.target.attributes.dataprice.nodeValue,
            totalprice: e.target.value*e.target.attributes.dataprice.nodeValue
        };

        props.datagoals(backupItem);
    }

    const changePromo = function(e){
        e.preventDefault();
        promo = e.target.value;
    }

    const checkPromo = function(e){
        e.preventDefault();

        fetch('https://api.goaltiming.be/challenges/'+item.ChallengeId+'/promos', {
            method: 'POST',
            body: JSON.stringify({"code": promo})
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(result.success === true){
                    var totalPromo = 0;
                    validatePromo = promo;
                    promoValue = Number(result.discount);

                    for(var i = 0; i<item.sections[props.datanumber].customFields.length; i++){
                        if(item.sections[props.datanumber].customFields[i].check === true){
                            totalPromo = Number(totalPromo) + Number(item.sections[props.datanumber].customFields[i].reduction);
                        }
                    }
            
                    totalPromo = Number(totalPromo) + Number(promoValue);
                    props.cdiscount(Number(totalPromo));

                    props.alertpromo('msg-success show', 'Le code a bien été validé. Vous avez ' + result.discount + '€ de réduction sur la course.');

                }else{
                    props.alertpromo('msg-error show', 'Code erroné ou déjà utilisé');
                }
            },(error) => {
                console.log(error);
            }
        )
    }

    const customField = function(e){
        e.preventDefault();
        var value = e.target.value;
        var target = e.target.attributes.target.nodeValue;
        var totalPromo = 0;

        if(value !== ''){
            item.sections[props.datanumber].customFields[target].check = true;
            item.sections[props.datanumber].customFields[target].value = value;
        }else{
            item.sections[props.datanumber].customFields[target].check = false;
            item.sections[props.datanumber].customFields[target].value = '';
        }

        for(var i = 0; i<item.sections[props.datanumber].customFields.length; i++){
            if(item.sections[props.datanumber].customFields[i].check === true){
                totalPromo = Number(totalPromo) + Number(item.sections[props.datanumber].customFields[i].reduction);
            }
        }

        totalPromo = Number(totalPromo) + Number(promoValue);
        props.cdiscount(Number(totalPromo));
    }

    return (
        <div>
        <form onSubmit={handleSubmit(addAnother)} className="step" id="step_2">

            <h2>2. Vos informations</h2>

            <div className="row">
                <div className="col-md-6">
                    <label>Nom *</label>
                    <input type="text" name="name" placeholder="Nom" ref={register({required: true})} />
                    <small className="form-text form-error">{errors.name && <span>Ce champ est requis</span>}</small>
                </div>
                <div className="col-md-6">
                    <label>Prénom *</label>
                    <input type="text" name="firstname" placeholder="Prénom" ref={register({required: true})} />
                    <small className="form-text form-error">{errors.firstname && <span>Ce champ est requis</span>}</small>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <label>Sexe *</label>
                    <div className="row">
                        <div className="col"><input type="radio" name="sex" value="F" ref={register({required: true})} /> Femme</div>
                        <div className="col"><input type="radio" name="sex" value="H" id="sexe_woman" ref={register({required: true})} /> Homme</div>
                    </div>
                    <small className="form-text form-error"><br />{errors.sex && <span>Ce champ est requis</span>}</small>
                </div>
                <div className="col-md-6">
                    <label>Date de naissance *</label>
                    <input type="date" name="dob" ref={register({required: true})} />
                    <small className="form-text form-error">{errors.dob && <span>Ce champ est requis</span>}</small>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <label>Email *</label>
                    <input type="text" name="email" placeholder="email@email.be" ref={register({required: true, pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i }})} />
                    <small className="form-text form-error">{errors.email && <span>Ce champ est requis</span>}</small>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <label>Adresse *</label>
                    <input type="text" name="address" placeholder="Adresse" ref={register({required: true})} />
                    <small className="form-text form-error">{errors.address && <span>Ce champ est requis</span>}</small>
                </div>
            </div>

            <div className="row">
            <div className="col-md-3">
                <label>Code Postal *</label>
                <input type="number" name="cp" placeholder="Code Postal" ref={register({required: true})} />
                <small className="form-text form-error">{errors.cp && <span>Ce champ est requis</span>}</small>
            </div>
            <div className="col-md-9">
                <label>Ville *</label>
                <input type="text" placeholder="Ville" name="city" ref={register({required: true})} />
                <small className="form-text form-error">{errors.city && <span>Ce champ est requis</span>}</small>
            </div>
            </div>

            <div className="row">
            <div className="col-md-12">
                <label>Pays *</label>
                <input type="text" placeholder="Pays" name="country" ref={register({required: true})} />
                <small className="form-text form-error">{errors.country && <span>Ce champ est requis</span>}</small>
            </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <label>Club</label>
                    <input type="text" name="club" placeholder="Club" ref={register} />
                </div>
            </div>

            <h2>3. Pour terminer...</h2>

            {item.sections[props.datanumber].customFields.map((item, i) =>
                <div className="row" key={i}>
                    {item.values ? (
                    <div className="col-md-12">
                        <label>
                            {item.tooltip ? (
                                <span className="customTooltip">
                                    <button data-tip data-for={'customTooltip' + i}>i</button>
                                    <ReactTooltip id={'customTooltip' + i} place="right" effect="solid" className="bullet">
                                        {item.tooltip}
                                    </ReactTooltip>
                                </span>
                            ) : ('')}
                            {item.label}
                        </label>
                        <select name={'custom_' + i} target={i} ref={register({required: true})} onBlur={customField} onChange={customField}>
                            <option value="">-- Choisir --</option>
                            {customFields[i].map((value, j) =>
                                <option value={value} key={j}>{value}</option>
                            )}
                        </select>
                        <br />
                        <small className="form-text form-error">{errors['custom_' + i] && <span>Ce champ est requis</span>}</small>
                        <br />
                        <br />
                    </div>
                    ) : (
                    <div className="col-md-12">
                        <label>
                            {item.tooltip ? (
                                <span className="customTooltip">
                                    <button data-tip data-for={'customTooltip' + i}>i</button>
                                    <ReactTooltip id={'customTooltip' + i} place="right" effect="solid" className="bullet">
                                        {item.tooltip}
                                    </ReactTooltip>
                                </span>
                            ) : ('')}
                            {item.label}
                        </label>
                        <input type="text" name={'custom_' + i} target={i} ref={register} onBlur={customField} onChange={customField} />
                    </div>
                    )}
                </div>
            )}

            {item.items.map((it, i) =>
                <div className="row item-shop" key={i}>
                <div className="col-md-6 item-img">
                    <h3>
                        {it.tooltip ? (
                            <span className="itemTooltip">
                                <button data-tip data-for={'itemTooltip' + i}>i</button>
                                <ReactTooltip id={'itemTooltip' + i} place="right" effect="solid" className="bullet">
                                    {it.tooltip}
                                </ReactTooltip>
                            </span>
                        ) : ('')}
                        {it.name}
                    </h3>
                    <div>
                        {it.image != '' ? (
                            <img src={'https://www.goaltiming.be' + it.image} alt={it.name} width="auto" height="100px" />
                        ) : ('')}
                    </div>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3 item-quantity">
                    <label>{it.price}€/pièce</label>
                    {item.ChallengeId == '7c83a700-2be5-11eb-8884-ebc5abb366b6' ? (
                        <div>
                            <input type="number" min="3" ref={register({required: true})} placeholder="0" step="1" name={'item_' + i} className="itemCharity" dataname={it.name} datakey={i} dataprice={it.price} onChange={handleChange} />
                            <br />
                            <small>Montant minimum 3€</small>
                        </div>
                    ) : (
                        <div>
                            <input type="number" min="0" placeholder="0" step="1" name={'item_' + i} dataname={it.name} datakey={i} dataprice={it.price} onChange={handleChange} />
                        </div>
                    )}
                </div>
                </div>
            )}
            <div className="rgpd">
                <div className="row">
                    <div className="col-1"><input type="checkbox" name="accept_rgpd" id="rgpd" ref={register({required: true})} defaultChecked /></div>
                    <div className="col-11"><label htmlFor="rgpd">J'autorise Goal Timing et les organisateurs de l'événement à utiliser mes données dans le cadre de l'organisation de cet événement.</label></div>
                </div>
                <small className="form-text form-error">{errors.accept_rgpd && <span>Ce champ est requis</span>}</small>
                <div className="row">
                    <div className="col-1"><input type="checkbox" name="accept_newsletter" id="newsletter" ref={register} /></div>
                    <div className="col-11"><label htmlFor="accept_newsletter">J'autorise Goal Timing et les organisateurs de l'événement à utiliser mes données dans le cadre de publicité et de mailings.</label></div>
                </div>
                <small className="form-text form-error">{errors.accept_newsletter && <span>Ce champ est requis</span>}</small>
                <div className="row">
                    <div className="col-1"><input type="checkbox" name="is_in_good_health" id="is_in_good_health" ref={register({required: true})} /></div>
                    <div className="col-11"><label htmlFor="is_in_good_health">Je déclare être en bonne condition physique et apte à la pratique du sport en compétition.</label></div>
                </div>
                <small className="form-text form-error">{errors.is_in_good_health && <span>Ce champ est requis</span>}</small>
                <div className="row">
                    <div className="col-1"><input type="checkbox" name="accept_rules" id="accept_rules" ref={register({required: true})} /></div>
                    <div className="col-11"><label htmlFor="accept_rules">J'accepte le <a href={'https://www.goaltiming.be' + item.addRules} target="_blank">règlement du challenge</a>.</label></div>
                </div>
                <small className="form-text form-error">{errors.accept_rules && <span>Ce champ est requis</span>}</small>
            </div>

            <div className="block-payment">
                <hr />
                <div className="row">
                    <div className="col-md-12"><strong>Code promo</strong></div>
                    <div className="row">
                        <div className="col-9"><input type="text" onChange={changePromo} className="inputPromo" /></div>
                        <div className="col-3"><button className="button orange" onClick={checkPromo}>Valider</button></div>
                    </div>
                </div>
                <Messages type={props.typepromo} message={props.msgpromo} />
            </div>
            <button type="button" className="button submitbtn orange" onClick={handleSubmit(addAnother)}>Ajouter au panier</button>
        </form>
        {props.cart.length > 0 ? (
            <form onSubmit={handlePayment(onSubmit)}>
                <div className="block-payment">
                <hr />
                <div className="row">
                    <div className="col-md-12"><strong>Mode de paiement</strong></div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <img src={CardBancontact} alt="Bancontact" />
                        <img src={CardCredit} alt="Carte de crédit" />
                    </div>
                </div>
                </div>
                <a href="#top" className={"button submitbtn orange addother " + props.waitingmsg}>Ajouter un autre challenger</a>
                <button type="button" className={"button submitbtn orange " + props.waitingmsg} onClick={handlePayment(onSubmit)}>Confirmer mon panier et payer</button>
                <p className={"waiting-message " + props.waitingmsg}>Redirection vers la plateforme de paiement en cours, veuillez patienter...</p>
            </form>
        ) : ('')}
                    
        </div>
    );
}