import React, {Component} from 'react'
import Trophy from "../assets/images/svg/trophy.svg";
import Laurel from "../assets/images/svg/laurel.svg";


class LastResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          items: [],
        };
    }
    
    componentDidMount() {
        fetch("https://api.goaltiming.be/events?type=all")
          .then(res => res.json())
          .then(
            (result) => {
                const events = result;
                const sortedEvents = events.sort((a, b) =>  b.date - a.date);
                const selectedEvents = [];
            
                for (var i = 0; i<sortedEvents.length; i++) {
                  var haveResults = false; 
                  for(var j = 0; j<sortedEvents[i].races.length; j++){
                    if(sortedEvents[i].races[j].results){
                      haveResults = true;
                    }                    
                  }

                  if(haveResults){
                    selectedEvents.push({
                      name : sortedEvents[i].name,
                      id : sortedEvents[i].EventId
                    });
                  }
                }

                var N = 3;
                if(N > selectedEvents.length){
                  N = selectedEvents.length;
                }

                var onlyThree = [];
                for(var i = 0; i<N; i++){
                  onlyThree.push(selectedEvents[i]);
                }

                this.setState({
                    isLoaded: true,
                    items: onlyThree
                });
            },(error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
    }
    render() {
        return (
            <div className="container">
                <h2 className="title">Derniers résultats <img alt="" src={Laurel} /></h2>

                <div className="row">
                    {this.state.items.map(function(item, i){
                        return  <div className="col-md-4 col-sm-12" key={i}>
                                    <a className="lr-box" href={'/courses/' + item.id}>
                                        <img alt="" src={Trophy} />
                                        <h3 className="lr-box-title">{item.name}</h3>
                                    </a>
                                </div>; 
                    })}
                </div>

                <div className="center">
                    <a href="/resultats" className="button">Voir tous les résultats</a>
                </div>
            </div>
        );
    }
}

export default LastResults