import React, {Component} from 'react';

export default class Messages extends Component {

    render(){
        return (
            <div className={this.props.type}>
                <p>
                    <span dangerouslySetInnerHTML={{__html: this.props.message}} />
                </p>
            </div>
        )
    }
}