import React, {Component} from 'react'
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';


class Map extends Component {
  render() {
    import('../assets/css/home.css');

    const events = this.props.events;
    
    return (
      <LeafletMap
        center={[50.4167, 4.4333]}
        zoom={10}
        minZoom={10}
        maxZoom={16}
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
      >
        <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
        {events.map(function(item, i){
           return <Marker position={[item.latitude, item.longitude]} key={i}>
                    <Popup>
                      <a href={"/courses/" + item.EventId}>{item.name}</a>
                    </Popup>
                  </Marker>; 
        })}
      </LeafletMap>
    );
  }
}

export default Map