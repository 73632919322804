import React, {Component} from 'react'
import Calendar from "../assets/images/svg/calendar.svg";


class UpcomingEvents extends Component {
  render() {
    import('../assets/css/home.css');

    const events = this.props.events;
    const sortedEvents = events.sort((a, b) => a.date - b.date);
    const selectedEvents = [];

    var N;
    if(sortedEvents.length > 3){
      N = 3;
    }else{
      N = sortedEvents.length;
    }

    for (var i = 0; i<N; i++) {
      var date = new Date(sortedEvents[i].date*1000);
      var day = date.getDate();
      var month = date.getMonth()+1;
      var year = date.getFullYear();

      selectedEvents.push({
        name : sortedEvents[i].name,
        date : day + '/' + month + '/' + year,
        banner : sortedEvents[i].banner,
        id : sortedEvents[i].EventId
      });
    }
    return (
      <div className="container">
          <h2 className="title"><img alt="" src={Calendar} /> Arrivent bientôt...</h2>

          <div className="row">
              {selectedEvents.map(function(item, i){
                 return <div className="col-md-4 col-sm-12" key={i}>
                            <div className="ue-box">
                                <img alt="" src={'https://www.goaltiming.be' + item.banner} />
                                <h3 className="ue-box-title">{item.name}</h3>
                                <p className="ue-box-infos">{item.date} <a href={"/courses/" + item.id}>Inscription</a></p>
                            </div>
                        </div>; 
              })}
          </div>
      </div>
    );
  }
}

export default UpcomingEvents