import React, {Component} from 'react';

export default class Menu extends Component {
    render(){
        return (
            <div className="menu-container">
                <h1><a href="/" data-hover="Accueil">Goal <span>Timing</span></a></h1>
                <nav>
                    <ul className="main-menu">
                        <li className="menu-home"><a href="/" data-hover="Accueil">Accueil</a></li>
                        <li><a href="/nos-courses" data-hover="Nos Courses">Nos Courses</a></li>
                        <li><a href="/resultats" data-hover="Résultats">Résultats</a></li>
                        <li><a href="/nos-challenges" data-hover="Nos Challenges">Nos Challenges</a></li>
                        <li><a href="/#contact" data-hover="Contact">Contact</a></li>
                    </ul>
                </nav>
            </div>
        )
    }
}