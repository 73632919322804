import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.css';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import Home from './pages/Home';
import Runs from './pages/Runs';
import OurRuns from './pages/OurRuns';
import OurResults from './pages/OurResults';
import Runners from './pages/Runners';
import OurChallenges from './pages/OurChallenges';
import Challenges from './pages/Challenges';

const routing = (
  <Router>
    <div>
      <Route exact path="/" component={Home} />
      <Route path="/nos-courses" component={OurRuns} />
      <Route path="/nos-challenges" component={OurChallenges} />
      <Route path="/resultats" component={OurResults} />
      <Route path="/participants/:eid/:rid" component={Runners} />
      <Route path="/courses/:id" component={Runs} />
      <Route path="/challenges/:id" component={Challenges} />
    </div>
  </Router>
)
      // <Route component={Notfound} />

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
