import React, {Component} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Map from '../components/Map';
import UpcomingEvents from '../components/UpcomingEvents';
import LastResults from '../components/LastResults';
import ContactForm from '../components/ContactForm';
import Loader from "../assets/images/svg/loader.svg";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      contactType: 'msg-success',
      contactMsg: 'Merci, nous vous recontacterons dans les plus bref délais.',
    };
    this.sendMessage = this.sendMessage.bind(this);
  }

  componentDidMount() {
    fetch("https://api.goaltiming.be/events")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result
          });
        },(error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  sendMessage(){
    this.setState({
      contactType: 'msg-success show',
    });
  }

  render() {
    import('../assets/css/home.css');

    const { error, isLoaded, items } = this.state;

    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader"><img src={Loader} alt="Load" /></div>;
    } else {
      return (

          <div className="page-home">

            <Header />

            <section className="section-map" id="ourruns">
                <Map events={items} />
            </section>


            <section className="section-upcomingevents">
                <UpcomingEvents events={items} />
            </section>

            {/*
            <section className="section-numbers">
                <img alt="" className="pict" src={Stats} />
                <div className="container">
                    <ul className="row">
                        <li className="col-sm"><img alt="" src={Clock} /><p>1234</p><h3>Courses</h3></li>
                        <li className="col-sm"><img alt="" src={Sprint} /><p>1234</p><h3>Résultats</h3></li>
                        <li className="col-sm"><img alt="" src={Running} /><p>12441</p><h3>Participants</h3></li>
                    </ul>
                </div>
            </section>
            */}

            <section className="section-lastresult" id="lastresult">
              <LastResults />
            </section>

            <section className="section-infos" id="contact">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-4">
                            <div className="i-box">
                                <iframe src="/facebook.html" frameBorder="0" height="320"></iframe>
                            </div>
                        </div> */}
                        <div className="col-md-3">
                            <div className="i-box">
                                <h3 className="i-box-title">Liens Utiles</h3>

                                <ul>
                                    <li><a href="/nos-courses">Nos courses</a></li>
                                    <li><a href="/resultats">Résultats</a></li>
                                    <li><a href="/#contact">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="i-box">
                                <h3 className="i-box-title">Contact</h3>

                                <ContactForm type={this.state.contactType} msg={this.state.contactMsg} sendmessage={this.sendMessage} eventid="global" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            
          </div>
      );
    }
  }
}

export default Home