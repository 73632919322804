import React from 'react';

export default function Sidebar(props) {

    // console.log(props.item.frais)
    
    // ============ CONVERT TIMESTAMP TO STRING DATE
    const convertToTime = function(timestamp){
        var date = new Date(timestamp*1000);
        var day = date.getDate();
        var month = date.getMonth()+1;
        var year = date.getFullYear();

        return day + "/" + month + "/" + year;
    }

    return (
        <div className="sidebar">

            {props.trad ? (
                <h3>Informations complémentaires / <br /> Bijkomende informatie</h3>
            ) : (
                <h3>Informations complémentaires</h3>
            )}

            {props.trad ? (
                <h4>Adresse / Adres</h4>
            ) : (
                <h4>Adresse</h4>
            )}
            <p dangerouslySetInnerHTML={{__html: '<b>' + props.item.place + '</b><br />' + props.item.address}} />

            {props.trad ? (
                <h4>Date de l'évènement /<br /> Datum van het evenement</h4>
            ) : (
                <h4>Date de l'évènement</h4>
            )}
            <p>
                {props.item.startDate != -3600 && props.item.startDate != '' ? (
                <span>du {convertToTime(props.item.startDate)} au </span>
                ) : ('')}
                {convertToTime(props.item.date)}
            </p>

            {!props.external_register ? (
            <div>
                {props.trad ? (
                    <div>
                        <h4>Ouverture des inscriptions / <br /> Inschrijvingen</h4>
                        <p>Du / Van {convertToTime(props.item.registrationStartDate)} <br /> au / tot {convertToTime(props.item.registrationEndDate)}</p>
                    </div>
                ) : (
                    <div>
                        <h4>Ouverture des inscriptions</h4>
                        <p>Du {convertToTime(props.item.registrationStartDate)} au {convertToTime(props.item.registrationEndDate)}</p>
                    </div>
                )}
            </div>
            ) : ('')}

            <div className="pricing">
                {props.trad ? (
                    <h3>Heure de départ / <br /> Uur van aanvang</h3>
                ) : (
                    <h3>Heure de départ</h3>
                )}
                {props.races.map((item, i) =>
                    <div className="row" key={i}>
                    <div className="col-9 name">{item.name}</div>
                    <div className="col-3 price">{item.departure}</div>
                    </div>
                )}
                {!props.external_register ? (
                    <div>
                    {props.trad ? (
                        <h3>Frais de pré-inscription / <br /> Deelnamekosten bij vooraf inschrijven</h3>
                    ) : (
                        <h3>Frais de pré-inscription</h3>
                    )}
                    {props.races.map((item, i) =>
                        <div key={i}>
                        {item.preRegistrationPrices < 0 && 
                            <div className="row">
                                {props.trad ? (
                                    <div className="col-12 name">{item.name} - Pas de pré-inscription / <br /> Geen voorregistratie</div>
                                ) : (
                                    <div className="col-12 name">{item.name} - Pas de pré-inscription</div>
                                )}
                            </div>
                        }
                        {item.preRegistrationPrices >= 0 && 
                            <div className="row">
                                <div className="col-9 name">{item.name}</div>
                                <div className="col-3 price">
                                    {item.preRegistrationPrices}€ 
                                    {props.item.frais > 0 && 
                                        <span className={item.showFees}>*</span>
                                    }
                                </div>
                            </div>
                        }
                        </div>

                        
                    )}
                    </div>
                ) : ('')}

                {props.trad ? (
                    <h3>Frais d'inscription sur place / <br /> Deelnamekosten ter plaatse</h3>
                ) : (
                    <h3>Frais d'inscription sur place</h3>
                )}
                {props.races.map((item, i) =>
                    <div key={i}>
                    {item.price < 0 && 
                        <div className="row">
                            {props.trad ? (
                                <div className="col-12 name">{item.name} - Pas d'inscription sur place / <br /> Geen registratie ter plaatse</div>
                            ) : (
                                <div className="col-12 name">{item.name} - Pas d'inscription sur place</div>
                            )}
                        </div>
                    }
                    {item.price >= 0 && 
                        <div className="row">
                            <div className="col-9 name">{item.name}</div>
                            <div className="col-3">{item.price}€</div>
                        </div>
                    }
                    </div>
                )}
            </div>
            {props.item.frais > 0 && 
                <p className={props.item.showFees}><br />* + frais de dossier </p>
            }
        </div>
    );
}