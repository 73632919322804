import React, {Component} from 'react';
import Loader from "../assets/images/svg/loader.svg";
import { MDBDataTable, MDBBtn } from 'mdbreact';

class Participants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      datas: []
    };
  }
  
  componentDidMount() {
    let rows = [];
    let count = 1;

    for(let i = 0;i<this.props.races.length;i++){
        let race = this.props.races[i];
        
        fetch('https://api.goaltiming.be/participants/events/'+this.props.eventid+'/races/' + race.id + '/registered', 
        { method: 'GET' })
          .then(res => res.json())
          .then(
            (result) => {
              for(let j = 0;j<result.length;j++){
                rows.push({
                  lastname: result[j].name.toLowerCase(),
                  firstname: result[j].firstname.toLowerCase(),
                  completename: result[j].name.replace(/\s/g, "") + ' ' + result[j].firstname.replace(/\s/g, ""),
                  run: race.name
                })
              }
                            
              if(count >= this.props.races.length){

                rows.sort((a,b) => (a.completename.toUpperCase() > b.completename.toUpperCase()) ? 1 : ((b.completename.toUpperCase() > a.completename.toUpperCase()) ? -1 : 0))

                this.setState({
                  datas: {
                    columns: [
                      {
                        label: 'Nom',
                        field: 'lastname',
                        sort: 'asc',
                      },
                      {
                        label: 'Prénom',
                        field: 'firstname',
                        sort: 'asc',
                      },
                      {
                        label: 'Course',
                        field: 'run',
                        sort: 'asc',
                      }
                    ],
                    rows: rows
                  },
                  isLoaded: true,
                });
              }
              count++;
            },(error) => {
                console.log(error);
            }
        )
    }
  }

  render() {
    const { error, isLoaded } = this.state;

    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader"><img src={Loader} alt="Loader" /></div>;
    } else{
      return (

        <div className="Participants">
          <h3 className="i-box-title">Participants</h3>
          <MDBDataTable striped bordered hover data={this.state.datas} />
        </div>
        
      );
    }
  }
}

export default Participants